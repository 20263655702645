import { DownloadURL } from "constants/download";
import React from "react";
import { getDeviceOS, OS } from "utils/detect-os";
import Redirect from "components/Redirect";
import { URLwithUTMSuffix } from "utils/download";

const AppDownload: React.FC = () => {
    const photosPlayURL = URLwithUTMSuffix(DownloadURL.googlePlay);
    const photosAppStoreURL = URLwithUTMSuffix(DownloadURL.appStore);

    const os = getDeviceOS();
    let to = "/";
    if (os === OS.Android) {
        to = photosPlayURL;
    } else if (os === OS.IOS) {
        to = photosAppStoreURL;
    }
    return <Redirect to={to} />;
};

export default AppDownload;
